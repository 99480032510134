
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import moment from "moment";
import OtBillLadingSelect from "@/components/catalogs-select/OtBillLadingSelect.vue";
import OtBookingSelect from "@/components/catalogs-select/OtBookingSelect.vue";
import ImoDeclarationForm from "@/components/maritime-file/forms/ImoDeclarationForm.vue";
import router from "@/router";
import CardBasicImoDeclaration from "@/components/cards/CardBasicImoDeclaration.vue";
import MaritimeFileSelect from "@/components/catalogs-select/MaritimeFileSelect.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "ImoDeclarations",
  components: {
    MaritimeFileSelect,
    CardBasicImoDeclaration,
    ImoDeclarationForm,
    OtBookingSelect,
    OtBillLadingSelect,
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const search = ref("");
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const totalFiles = computed(() => store.getters.TotalFiles);
    const dataList = ref([]);
    const filtered = ref([]);
    const viewType = ref(user.value.user_preferences.view_type_items);
    const originalData = ref([]);
    const bls = ref("");
    const bookings = ref("");
    const searchmf = ref("");
    const created_date = ref([]);
    const otSelected = ref("");
    const form = ref(false);
    const header = ref([
      { column: "istatus", key: "status.outbound" },
      { column: "idispatcher", key: "dispatcher.legal_name" },
      { column: "irecipient", key: "recipient.legal_name" },
      { column: "tibookings", key: "booking.booking_number" },
      { column: "tiBillOfLadings", key: "bills_of_lading.bl_number" },
      {
        column: "imessaging_operation_instruction",
        key: "messaging_operation_instruction.name",
      },
      {
        column: "imessaging_transport_method",
        key: "messaging_transport_method.name",
      },
      {
        column: "imessaging_iftdgn_cargo_type",
        key: "messaging_iftdgn_cargo_type.name",
      },
      {
        column: "imessaging_groupage_type",
        key: "messaging_groupage_type.name",
      },
    ]) as any;
    const createElement = () => {
      form.value = true;
    };
    const seeImoDetails = (ot) => {
      const tab = "billOfLading";
      const item_id = ot["bills_of_lading_id"];
      const mf_id = ot["maritime_file_id"];
      store.commit("setBlBoTab", {
        tab: "four",
        item_id: ot["id"],
      });
      store.commit("setMaritimeFileTab", {
        tab: tab,
        item_id: item_id,
      });
      if (ot["bills_of_lading_id"]) {
        router.push("/maritimefile/" + mf_id);
      } else {
        alert("No existe BOL");
      }
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter(
            (x) => x.name === "dangerous goods form: create"
          ).length > 0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter(
            (x) => x.name === "dangerous goods form: show"
          ).length > 0
        );
      }
      return false;
    });

    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter(
            (x) => x.name === "dangerous goods form: update"
          ).length > 0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter(
            (x) => x.name === "dangerous goods form: delete"
          ).length > 0
        );
      }
      return false;
    });

    const preferences = localStorage.getItem("preferences") as any;
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: totalFiles.value,
      last_page: Math.floor(totalFiles.value / 12),
    });

    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
    ]);

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const createFieldItems = (data) => {
      return data.map(function (element) {
        element.items = {
          idispatcher: element.dispatcher.legal_name,
          irecipient: element.recipient.legal_name,
          imessaging_operation_instruction:
            element.messaging_operation_instruction.name,
          imessaging_transport_method: element.messaging_transport_method.name,
          imessaging_iftdgn_cargo_type:
            element.messaging_iftdgn_cargo_type.name,
          imessaging_groupage_type: element.messaging_groupage_type.name,
          tibookings: element.booking.booking_number,
          tiBillOfLadings: element.bills_of_lading.bl_number,
        };
        return element;
      });
    };

    const getItems = async () => {
      ApiService.query(`/api/dangerous_goods_form`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          booking_id: bookings.value,
          bills_of_lading_id: bls.value,
          maritime_file_id: searchmf.value,
          date_created: created_date.value,
        },
      }).then((response) => {
        store.commit("setDeclarationTable", response.data.data);
        originalData.value = response.data.data;
        dataList.value = createFieldItems(response.data.data);
        pagination.value.total = response.data.total;
        pagination.value.last_page = response.data.last_page;
        filtered.value =
          viewType.value === "card"
            ? response.data.data
            : createTableItems(response.data.data);
        onSearch();
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [
        { route: "/imo_declarations", label: "timodeclaration" },
      ]);
      getItems();
    });

    const currentPageChange = (val) => {
      pagination.value.page = val;
      getItems();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      getItems();
    };

    const onSearch = () => {
      let data = createFieldItems(dataList.value);
      if (viewType.value == "table") {
        data = createTableItems(dataList.value);
      }
      filtered.value = data.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          status_led:
            element.edi_paths.length === 0 ? "none" : getColorTable(element),
          bl: element.bills_of_lading
            ? element.bills_of_lading.bl_number
            : " - ",
          transportation_type:
            element.transportation_type == "P"
              ? "Propio"
              : element.transportation_type == "C"
              ? "Cedido"
              : element.transportation_type,
          admission_status:
            element.admission_status == "F"
              ? t("iis_full")
              : element.admission_status == "E"
              ? t("iis_empty")
              : element.admission_status,
          type: element.type.name,
          edi_path_status:
            element.edi_paths.length > 0
              ? element.edi_paths[element.edi_paths.length - 1].status
              : t("file_not_generated"),
          description:
            element.description.length > 25
              ? element.description.substr(0, 20) + "  ..."
              : element.description,
          accouting_date: formatDate(element.created_at),
        });
      });
      return result;
    };

    const seeDetails = (id) => {
      otSelected.value = id;
      form.value = true;
    };

    const reload = () => {
      getItems();
      form.value = false;
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        seeDetails(dataList.value[param3]["id"]);
      }
    };

    const getBackgroundClass = (ot) => {
      switch (ot.edi_paths[ot.edi_paths.length - 1].status) {
        default:
          return "bg-secondary text-black";
      }
      /*switch (ot.id) {
        case 1:
          return "bg-primary";
        case 2:
          return "bg-warning";
        case 3:
          return "bg-danger";
        case 4:
          return "bg-success";
        default:
          return "bg-secondary";
      }*/
    };

    const getColorTable = (ot) => {
      switch (ot.edi_paths[ot.edi_paths.length - 1].status) {
        default:
          return "none";
      }
    };

    return {
      created_date,
      canCreate,
      canUpdate,
      canDelete,
      canShow,
      bls,
      bookings,
      filtered,
      dataList,
      search,
      viewType,
      header,
      tableButtons,
      pagination,
      form,
      otSelected,
      searchmf,
      formatDate,
      onSearch,
      getItems,
      currentPageChange,
      setItemsPerPage,
      seeDetails,
      seeImoDetails,
      reload,
      actionsButton,
      getBackgroundClass,
      getColorTable,
      createElement,
      user,
    };
  },
});
