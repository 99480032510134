
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "OtBillLadingSelect",
  props: {
    bl_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:bl_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const blData = computed(() => store.getters.BL);
    const element_id = ref("");
    const inputBL = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isBLRequired = (value) => {
      if (props.required && !value) {
        return t("rbl");
      }
      return true;
    };

    const { errorMessage, value } = useField("bl", isBLRequired);

    const getBL = (query) => {
      store.commit("setLoading", false);

      ApiService.query("/api/bills_of_lading/lists", {
        params: {
          per_page: 10,
          bl_number: query,
        },
      }).then(({ data }) => {
        //store.commit("setBLs", data.bills_of_lading);
        inputBL.value.list = data.bills_of_lading;
        inputBL.value.options = data.bills_of_lading;
      });
    };
    const selectBL = (query) => {
      if (query !== "" || query !== null) {
        inputBL.value.loading = true;
        setTimeout(() => {
          inputBL.value.loading = false;
          inputBL.value.options = inputBL.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.id.toString().toLowerCase().indexOf(query.toLowerCase()) >
                  -1 ||
                item.bl_number
                  .toString()
                  .toLowerCase()
                  .indexOf(query.toLowerCase()) > -1
              );
            }
          );
        }, 200);
      } else {
        inputBL.value.options = blData;
      }
    };

    watch(
      () => props.bl_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        getBL("");
      }, 3000);
    });

    return {
      element_id,
      inputBL,
      errorMessage,
      selectBL,
      getBL,
    };
  },
};
